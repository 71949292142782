import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/IT/layout'
import BannerLanding from '../../components/IT/BannerLanding'

import pic08 from '../../assets/images/lor2.jpg'
import pic09 from '../../assets/imgs/landing/p1.jpg'

import pic10 from '../../assets/imgs/landing/p2.jpg'
import pic11 from '../../assets/imgs/landing/q1.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <p style ={{ textAlign: "justify" }}>UNIDANS è un progetto complesso che coinvolge tre partner europei con lo
scopo di scambiarsi e confrontarsi sulle proprie esperienze in merito allo sport
integrato in generale e alla danza integrata in particolare.
In ogni Paese saranno circa 100 i tecnici sportivi e volontari che saranno formati
nel campo della danza integrata e circa 20 le organizzazioni e associazioni
operanti nel settore dell’inclusione sociale delle persone con disabilità attraverso
la danza e le arti performative che potranno conoscersi e fare rete attraverso gli
eventi di Networking targati UNIDANS.</p>

                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="/IT/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        
                        <div className="inner">
                            <header className="major">
                                <h3>Scopo Del Progetto </h3>
                            </header>
                            <p>Il progetto mira a promuovere l’educazione nello sport e attaverso lo sport con
un’attenzione particolare allo sviluppo delle competenze degli insegnanti che
lavorano nel campo dello sport integrato in generale e nella danza integrata in
particolare.</p>
                            <ul className="actions">
                                <li><Link to="/IT/generic" className="button">Scopri di più</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                 <section>
                    <Link to="/generic" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Obiettivi</h3>
                            </header>
                            <ul className="actions">
                                <li><Link to="/IT/objectives" className="button">Scopri di più</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Attivita’</h3>
                            </header>
                            <ul className="actions">
                                <li><Link to="/IT/activities" className="button">Scopri di più</Link></li>
                            </ul>
                        </div>
                    </div>
                </section> 
                <section>
                    <Link to="/IT/generic" className="image">
                        <img src={pic11} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>A Chi Ci Rivolgiamo </h3>
                            </header>
                            <ul className="actions">
                                <li><Link to="/IT/address" className="button">Scopri di più</Link></li>
                            </ul>
                        </div>
                    </div>
                </section> 
            </section>
        </div>

    </Layout>
)

export default Landing